.consulate-body {
    padding-top:70px;
    height:580px;
    box-sizing: border-box;
}

.booking-section {
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}

.booking-item {
    text-align: center;
    border-radius: 4px;
    padding:30px 30px;
    background-color:rgba(255,255,255,0.7);
    color: #000000;

    .book-name {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 25px;
        padding-bottom: 20px;
        letter-spacing: -1px;
        line-height: 15px;
    }

    .book-token {
        padding: 5px;
        letter-spacing: 1px;
        font-weight: bold;
        line-height: 1.6;
        opacity: 0.8;
    }
}

.counter-container {
    height:100%;
    border-radius: 4px;
    background-color:rgba(255,255,255,0.7);

    .counter-item {
        padding-top:15px;

        .class-counter {
            width: 100%;

        .cntr-heading {
            color: #007120;
            text-transform: uppercase;
            font-weight: 900;
            margin-bottom: 30px;
            padding: 20px;
            font-size: 50px;
            line-height: 70px;
            }

        .single-content {
            margin-top: 30px;
            padding-top: 10px;
            color: #000000;

            .cntr-at {
             font-weight: bold;
             font-size: 35px;
            }

            .cntr-number {
               font-weight: 600;
               font-size: 50px;
            }
        }

        .sing-token-no {
            text-transform: uppercase;
            line-height: 80px;
            font-size: 100px;
            font-weight: bold;
          }

        .multi-counter {
            height:300px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            word-spacing: 5px;

        .multi-cntr-item {
            display: flex;
            flex-direction: row;
            justify-content: center;

            .verticle-middle * {
                vertical-align: middle;
            }
        }

        .cntr-token {
            font-size: 45px;
            font-weight: bold;
        }
        .cntr-no {
            font-size: 30px;
            font-weight: bold;
        }
      }
    }
  }
}
